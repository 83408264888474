// login
.login
    height: 100vh
    position: relative
    .login-form
        overflow-y: scroll
        height: 100%
    .login-form--wrapper
        max-width: 450px
        width: 100%
        height: max-content
    .login-img
        background-image: url("../assets/images/portatil-tarjeta-recortada.jpg")
        background-position: 0 0
        background-repeat: no-repeat
        background-size: cover
    .new-add
        margin-top: 60px