@font-face {
  font-family: 'paperTicket';
  src: url('../../resources/font/ticketing.ttf') format('truetype');
}
.borderBottom {
  border-bottom: 1px solid #464646;
}

.textPaperTicket {
  font-family: "paperTicket";
  margin: 0;
  padding: 0;
  line-height: 110%;
}

.textPaperTicketTitle {
  font-family: "paperTicket";
  font-weight: 900;
  margin: 0;
  padding: 0;
  line-height: 110%;
}






