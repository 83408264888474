.msg-box {
  padding: 20px;
  left: 50%;
  max-width: 900px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 80%;
}

.msg-box img {
  max-width: 200px;
}

.msg-box h2 {
  color: #1c752c;
  font-family: kreon, serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 30px;
}

.msg-box h3 {
  color: #77bd1e;
  font-family: kreon, serif;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 10px;
}
